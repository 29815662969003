export default class FtRegistrasiFilter {
  constructor(
      id = 0,
      pageNo= 0,
      pageSize = 0,
      sortBy="id",
      order="DESC",
      search="",
      submittedOnly = false,

      fdivisionIds =[],
      fareaIds= [],
      ftemplateRegIds=[],
      validasiStatusIds =[],
      statusUsulanBean = 0,

      periodeTahun = 0,
      periodeBulan = 0,

      fjenisJabatanIds= [],

      trDateFrom= new Date(),
      trDateTo = new Date(),


  ) {
    this.id = id;
    this.pageNo = pageNo;
    this.pageSize = pageSize;
    this.sortBy = sortBy;
    this.order = order;
    this.search = search;

    this.submittedOnly = submittedOnly;

    this.fdivisionIds = fdivisionIds;
    this.fareaIds = fareaIds;
    this.ftemplateRegIds = ftemplateRegIds;
    this.validasiStatusIds = validasiStatusIds;
    this.statusUsulanBean = statusUsulanBean;

    this.periodeTahun = periodeTahun;
    this.periodeBulan = periodeBulan;

    this.fjenisJabatanIds = fjenisJabatanIds;

    this.trDateFrom = trDateFrom;
    this.trDateTo = trDateTo;

  }

}
