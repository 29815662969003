<template>
    <v-card class="mt-4 mx-2 elevation-0">
        <FtRegistrasiLayananMainTable/>
    </v-card>
  </template>
  
  <script>
  import FtRegistrasiLayananMainTable from "@/components/admin_registrasi_layanan/FtRegistrasiLayananTable.vue";
  
  export default {
    name: "RegistrasiLayananTableView",
    components: {
      FtRegistrasiLayananMainTable
    },
    data() {
      return {
        tabTitle: 'Registrasi'
      }
    },
    computed: {
    },
    methods: {
  
    },
  
  
  }
  </script>
  
  <style scoped>
  </style>